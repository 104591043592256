.landing_list_con {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin: auto;
  align-items: baseline;
  color: #ff889c;
  font-size: 25px;
  line-height: 45px;
}
.landing_list_dot {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: #ff889c;
  position: absolute;
  left: -8.6px;
  top: -5px;
}
.landing_list_item {
  margin-left: 20px;
  margin-top: 0;
}
.landing_list_subcon {
  position: relative;
  border-left: 2px solid #ff889c;
  padding-bottom: 20px;
}
.center_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.landing_image {
  width: 70%;
  height: 150px
}
.row_con {
  margin: 0;
  padding: 4rem 10rem;
  margin: 0;
  width: 100%;
}
.main_con {
  margin-bottom: 5rem;
  padding: 0;
}

.paragraphs {
  font-size: 20px;
}
.middle_image {
  margin-bottom: auto;
}

@media screen and (max-width: 1200px) {
  .row_con {
    padding: 3rem 4rem;
  }
  .main_con {
    margin-bottom: 3rem;
  } 
  .landing_image {
    width: 35%;
    height: 130px
  }
}

@media screen and (max-width: 767px) {
  .row_con {
    padding: 3rem 2rem;
  }
  .main_con {
    margin-bottom: 2rem;
  } 
  .landing_image {
    width: 50%;
    height: 120px
  }
  .middle_image {
    margin-bottom: 60px;
  }
}
@primary-color: #ff889c;@link-color: rgba(0, 0, 0, 0.8);@font-size-base: 15px;