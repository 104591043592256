.file_input {
  width: 100px;
  height: 100px;
  margin: auto 10px;
}
.upload_image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.upload_image_con {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 40vh;
  margin: auto 20px;
}

.left_card {
  padding-left: 10px !important;
}
@primary-color: #ff889c;@link-color: rgba(0, 0, 0, 0.8);@font-size-base: 15px;