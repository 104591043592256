.create_post {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.post_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: auto 20px;
  margin: auto;
  flex-direction: column;
}
.post_input_container input {
  width: 100%;
}
.post_submit_area {
  width: 100%;

  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.post_submit_area .post_announcement {
  width: 70%;
  margin: 10px auto 0 auto;
}
.post_submit_area .button {
  width: 20%; 
  margin: auto;
}

.poll_input_container {
  width: 80%;
  margin: auto;
}
.poll_input_container input {
  margin: 5px auto;
}
.poll_button_container {
  margin: 7px auto;
}
.poll_button_container button{
  margin: auto 3px;
}
.center_elem{
  display: flex;
  justify-content: center;
}

.project_form {
  width: 80%;
  margin: 10px auto;
}
.map_list {
  background-color: rgb(209, 209, 209);
  border-radius: 50px;
  cursor: pointer;
  margin: 2px;
  width: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
}

.delete_from_list {
  color: red !important;
  position: absolute;
  right: 10px;
  margin: 5px 0;
  font-size: 0.75rem;
}

@media screen and (max-width: 767px) {
  .post_input_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: auto 10px;
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
  .post_input_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: auto 10px;
    margin: auto;
  }
}

@primary-color: #ff889c;@link-color: rgba(0, 0, 0, 0.8);@font-size-base: 15px;