
/* Login/Register */
.form {
  padding: 13px 17px !important;
  border: 1px solid #eee;
  box-shadow: 0 5px 15px 0 #ccc;
  display: inline-block;
  text-align: center;
  width: 30%;
  align-content: center;
  justify-content: center;
}
.container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 30px auto;
}
.alert {
  margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
  .form {
    width: 45%;
  }
}

@media screen and (max-width: 1000px) {
  .form {
    width: 52%;
  }
}

@media screen and (max-width: 767px) {
  .form {
    width: 62%;
  }
}

@media screen and (max-width: 450px) {
  .container {
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 20px;
  }
  .form {
    width: 87%;
  }
}

@primary-color: #ff889c;@link-color: rgba(0, 0, 0, 0.8);@font-size-base: 15px;