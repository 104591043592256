html {
  height: 100% !important;
  overflow: auto !important;
}
body {
 height: 100% !important;
}
.ant-dropdown-trigger {
  border: none !important;
}
.below_nav {
  padding-top: 90px;
  min-height: 90vh;
}
.scroll_main{
  overflow-y: scroll;
  height: 80vh;
  overflow-x: hidden;
}

.ant-list-item {
    flex-wrap: wrap !important;
}

.ant-card-body {
  padding: 10px !important;
}

.ant-list-vertical .ant-list-item-action {
  margin-top: 0 !important;
}

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 0 !important;
}

.ant-divider-horizontal {
  margin: auto !important;
}

.ant-comment-inner {
  padding: 10px 0;
}

// .ant-divider-horizontal

@media screen and (max-width: 1000px) {
  .below_nav {
    padding-top: 140px;
  }
} 
@media screen and (max-width: 767px) {
  .below_nav {
    padding-top: 60px;
  }
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@primary-color: #ff889c;@link-color: rgba(0, 0, 0, 0.8);@font-size-base: 15px;