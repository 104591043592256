.footer {
  background: #ff889c;
  width: 100%;
  padding: 40px 10px;
  text-align: center;
  min-height: 100px;
}

.footer_logo {
  width: 60px;
}

.right_menu > div , .left_menu > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .right_menu {
    margin-top: 25px;
  }
  .left_menu {
    margin-bottom: 25px;
  }
}
@primary-color: #ff889c;@link-color: rgba(0, 0, 0, 0.8);@font-size-base: 15px;